
import { Options, Vue } from 'vue-class-component';

import Info from "@/types/Info";

@Options({
  props: {
    info: Object as () => Info,
  }
})
export default class InfoBlock extends Vue {

  info!: Info;

}
